body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, button, input, select, textarea, .MuiFormControl, .MuiInputLabel-root, .MuiOutlinedInput-root {
  font-family: "Heebo", sans-serif !important;
}

.sub-title {
  color: #6B7A90;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  font-family: "IBM Plex Sans", sans-serif;
}

h6 {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 60px;
}

.MuiContainer-root {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.site-header::before {
    content: '';
    position: absolute;
    top: -200px;
    right: 0px;
    width: 1440px;
    height: 324px;
    background-image: url(../public/header-illustration-light.svg);
}

.site-header {
  margin-bottom: 70px;
}

.main-app {
  padding-right: 16px;
  padding-left: 16px;
  min-height: 90vh;
}

.MuiButton-contained {
  background-color: #535FD7 !important;
}
.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.header-logo-image {
  width: 100px;
}

.form-container .MuiFormControl-root, .form-container .MuiButton-root {
  margin: 20px 0;
}

.MuiChip-root {
  margin-left: -5px;
}

.total-points-container {
  margin: 30px 16px;
}

.total-points-container label {
  margin: 0;
}

.total-points-container h2 {
  margin-bottom: 0;
}

.total-points-number {
  text-align: right;
  font-size: 32px;
  font-weight: bold;
  margin-top: 0;
  transition: opacity 1s ease-in-out;
}

.total-points-number span {
  font-size: 20px;
  margin-right: 10px;
  text-decoration: line-through;
}

.bonus-message {
  text-align: right;
  font-size: 20px;
  margin-top: -20px;
  color: #FE7E1F;
  font-weight: bold;
}

.MuiChip-root {
  color: #FFF !important;
  font-weight: bold;
  display: block;
}

.chip-cervezas {
  background-color: rgba(200, 0, 0, .8) !important;
}

.chip-licores {
  background-color: rgba(100, 200, 0, .7) !important;
}

.chip-vinos {
  background-color: rgba(100, 0, 200, .8) !important;
}

.chip-jugos {
  background-color: rgba(200, 100, 0, .8) !important;
}

.chip-aguas {
  background-color: rgba(0, 200, 200, .8) !important;
}

.chip-mixers-y-otros {
  background-color: rgba(184, 172, 26, .95) !important;
}

.chip-frascos {
  background-color: rgba(34, 65, 224, .88) !important;
}

.package-type {
  margin: 0;
}

.packages-total-quantity td {
  font-size: 18px;
  font-weight: bold;
  border-bottom: none;
  padding-top: 30px;
}

.schedule-message {
  font-size: 18px;
  margin-top: 40px;
}

footer {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  background: #182538;
  color: #6B7A90;
  padding: 30px 20px;
}

.site-footer-inner {
  display: flex;
  justify-content: space-between;
}

.site-footer-inner img {
  width: 100px;
}

.list-reset{
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 20px;
}

.list-reset li {
  margin-bottom: 10px;
}

.list-reset a {
  color: #6B7A90;
  text-decoration: none;
}

.social-networks img {
  display: inline;
  width: 30px;
  margin-top: -5px;
  margin-bottom: 10px;
}